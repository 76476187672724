import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography, IconButton, Avatar } from '@mui/material';
import moment from "moment";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const applyPagination = (data, page, limit) => data
    .slice(page * limit, page * limit + limit);

const StatusListTable = (props) => {
    const { arrayData, employeeName, siteName, isEdit, isDelete, ...other } = props;
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(25);
    const [headers, setHeaders] = useState([
        {
            id: "name",
            name: "Name"
        },
        {
            id: "time",
            name: "Time in-out"
        },
        {
            id: "duration",
            name: "Duration"
        },
        {
            id: "location",
            name: "Location"
        },
        {
            id: "notes",
            name: "Notes"
        },
        {
            id: "path",
            name: "Start Image"
        },
        {
            id: "endPath",
            name: "End Image"
        },
        {
            id: "action",
            name: ""
        },
    ])

    useEffect(() => {
        console.log("arrayData", arrayData)
        setPage(0);
    }, [arrayData]);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleLimitChange = (event) => {
        setPage(0);
        setLimit(parseInt(event.target.value, 10));
    };
    const paginatedArray = applyPagination(arrayData, page, limit);

    const onClick = (id) => {
        console.log(moment.parseZone("2024-06-25T00:00:00-05:00").format("hh:mm a"), " | ", moment.parseZone("2024-06-25T08:20:07-05:00").format("hh:mm a"))
        console.log(moment.parseZone("2024-06-24T16:12:41-05:00").format("hh:mm a"), " | ", moment.parseZone("2024-06-24T23:59:59-05:00").format("hh:mm a"))
    }

    return (
        <Box sx={{ overflowX: 'auto', minWidth: '100%', maxWidth: '100%' }}>
            <Table size="medium">
                <TableHead>
                    <TableRow style={{ backgroundColor: '#F5F7FB', height: '40px', padding: "0", margin: "0" }}>
                        {headers.map((header) => (
                            <TableCell key={header.id} style={{ paddingBlock: '0px' }}>
                                <Typography color="#262B40" variant="primary" fontFamily={"Roboto"} fontSize={'13px'} fontWeight='bold'>
                                    {header.name}
                                </Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {paginatedArray.map((data, index) => {
                        return (<React.Fragment key={data._id}>
                            <TableRow hover key={data._id} style={{ backgroundColor: '#EFEFEF', height: '38px', }}>
                                <TableCell style={{ paddingBlock: '0px' }} onClick={(e) => onClick(data.id)} colSpan={8}>
                                    <Typography color="#262B40" variant="primary" fontSize={'12px'} fontWeight='bold' paddingRight={"20px"}>
                                        {data.date}
                                    </Typography>
                                    <Typography color="#262B40" variant="primary" fontSize={'12px'} fontWeight='bold'>
                                        {data.time}
                                    </Typography>
                                </TableCell>
                            </TableRow>

                            {data.arrayData.map((employeeActivity, index) => {
                                return (
                                    <TableRow hover key={employeeActivity._id} style={{ height: '40px', }}>
                                        <TableCell style={{ paddingBlock: '0px' }} onClick={(e) => onClick(data.id)}>
                                            <Typography color="#262B40" variant="primary" fontSize={'13px'} fontWeight='normal' >
                                                {employeeName}
                                            </Typography>
                                        </TableCell>

                                        <TableCell style={{ paddingBlock: '0px' }} onClick={(e) => onClick(data.id)}>
                                            <Typography color="#262B40" variant="primary" fontSize={'13px'} fontWeight='normal'>
                                                {moment.parseZone(employeeActivity.localStartTime + "").format("hh:mm a")} - {moment.parseZone(employeeActivity.localEndTime + "").format("hh:mm a")}
                                            </Typography>
                                        </TableCell>

                                        <TableCell style={{ paddingBlock: '0px' }} onClick={(e) => onClick(data.id)}>
                                            <Typography color="#262B40" variant="primary" fontSize={'13px'} fontWeight='normal'>
                                                {employeeActivity.duration}
                                            </Typography>
                                        </TableCell>

                                        <TableCell style={{ paddingBlock: '0px' }} onClick={(e) => onClick(data.id)}>
                                            <Typography color="#262B40" variant="primary" fontSize={'13px'} fontWeight='normal'>
                                                {siteName}
                                            </Typography>
                                        </TableCell>

                                        <TableCell style={{ paddingBlock: '0px' }} onClick={(e) => onClick(data.id)}>
                                            <Typography color="#262B40" variant="primary" fontSize={'13px'} fontWeight='normal'>
                                                {employeeActivity.notes || "-"}
                                            </Typography>
                                        </TableCell>

                                        <TableCell style={{ paddingBlock: '0px' }} onClick={(e) => onClick(data.id)}>
                                            <Box ml={2}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    props.onImageClick(employeeActivity.path)
                                                }}>
                                                <Avatar
                                                    src={employeeActivity.path}
                                                    sx={{
                                                        color: "primary.main",
                                                        backgroundColor: '#DCDCDC',
                                                        height: "33px",
                                                        width: "33px",
                                                    }}
                                                >
                                                </Avatar>
                                            </Box>
                                        </TableCell>


                                        <TableCell style={{ paddingBlock: '0px' }} onClick={(e) => onClick(data.id)}>
                                            <Box ml={2}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    props.onImageClick(employeeActivity.endPath)
                                                }}>
                                                <Avatar
                                                    src={employeeActivity.endPath}
                                                    sx={{
                                                        color: "primary.main",
                                                        backgroundColor: '#DCDCDC',
                                                        height: "33px",
                                                        width: "33px",
                                                    }}
                                                >
                                                </Avatar>
                                            </Box>
                                        </TableCell>

                                        <TableCell align="right" style={{ paddingBlock: '0px' }} onClick={(e) => onClick(data.id)}>
                                            {isEdit && <IconButton
                                                sx={{ m: 0, p: 0 }}
                                                onClick={(e) => {
                                                    props.onEdit(employeeActivity)
                                                }}
                                                size="large">
                                                <EditIcon fontSize="medium" />
                                            </IconButton>}
                                            {/* {isDelete && <IconButton
                                                sx={{ m: 0, p: 0, pl: 2 }}
                                                onClick={(e) => {
                                                    props.onDelete(employeeActivity)
                                                }}
                                                size="large">
                                                <DeleteIcon fontSize="medium" />
                                            </IconButton>} */}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </React.Fragment>)
                    })}
                </TableBody>

            </Table>
            <TablePagination
                component="div"
                count={arrayData.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25]}
            />
        </Box>

    );

}


StatusListTable.propTypes = {
    arrayData: PropTypes.array.isRequired
};

export default StatusListTable;